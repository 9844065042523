

.app {
    width: 100%;
    height: 100vh;
}

.header{
    position: relative;
    z-index: 99;
    width: 100%;
    height: 65px;
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 65px);
    z-index: 0;
    background-color: #070709;
}

.video-container {
    object-fit: cover;

    .video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        object-fit: cover;
        padding: 0;
        margin: 0;
        opacity: 0.5;
    }
}

.img-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    opacity: 0.5;
}

.btn-wallet {
    width: 300px;
    height: 68px;
    background-color: rgb(41, 32, 38);
    border: 4px solid rgb(255, 0, 183);
    border-radius: 4px;
    box-shadow: rgb(255 0 183) 0px 0px 0.5em 0px inset, rgb(255 0 183) 0px 0px 0.5em 0px;
    z-index: 10;
    font-family: "MyFont";
    color: rgb(255, 0, 183);
    // text-shadow: rgb(255 255 255 / 50%) 0px 0px 0.125em, rgb(255 0 183) 0px 0px 0.5em;
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
}

.btn-wallet-disconnect {
    width: 300px;
    height: 90px;
    display: flex;
    padding: 12px 24px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(41, 32, 38);
    border: 4px solid rgb(255, 0, 183);
    border-radius: 4px;
    box-shadow: rgb(255 0 183) 0px 0px 0.5em 0px inset, rgb(255 0 183) 0px 0px 0.5em 0px;
    z-index: 10;
    font-family: "MyFont";
    color: rgb(255, 0, 183);
    font-size: 24px;
    font-weight: 400;
    cursor: pointer;
    // text-shadow: rgb(255 255 255 / 50%) 0px 0px 0.125em, rgb(255 0 183) 0px 0px 0.5em;
    .pubkey {
        font-size: 18px;
    }
}

.barCompleted {
    background-color: #55FFE1;
    width: 80%;
}

.container {
    background-color: grey;
}

.label {
    color:#070709;
}

.mint-home {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .mint-num {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
        margin-bottom: 30px;
        width: 100%;
        z-index: 10;

        .mint-num-text{
            color: white;
            font-size: 16px;
            font-family: "MyFont";
            margin-bottom: 10px;
        }
    }
    
    .user-mint-num {
        width: 100%;
        height: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        z-index: 10;

        .arrow-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border: none;
            outline: none;
            border-radius: 50%;
            background: linear-gradient(rgb(96, 74, 229) 0%, rgb(129, 62, 238) 100%);
        }

        .arrow-btn:disabled {
            opacity: 0.7;
        }

        .user-mint-num-val{
            width: 35px;
            height: 35px;
            text-align: center;
            border: none;
            border-radius: 3px;
            font-family: "MyFont";
            color: rgba(0, 0, 0, 0.7);
            font-size: 16px;
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .mint-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        z-index: 10;

        .mint-section-text{
            color: white;
            font-size: 16px;
            font-family: "MyFont";
            margin-bottom: 10px;
        }

        .mint-btn {
            width: 100%;
            height: 65px;
            color: white;
            font-family: "MyFont";
            font-size: 18px;
            background: linear-gradient(rgb(96, 74, 229) 0%, rgb(129, 62, 238) 100%);
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }
    }
}
